@import "../css/base.scss";

.ant-card-cover {
  height: 300px;
  overflow: hidden;
  position: relative;
  object-fit: none; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .tb-cover-desc {
    position: relative;
    z-index: 1;
  }
}
