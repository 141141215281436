@import "../css/base.scss";

.login-form {
  width: 100%;
  .ant-form-item {
    margin-bottom: 0;
  }
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
